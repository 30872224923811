@charset "UTF-8";
/* ---------------------------------------------------------
config
----------------------------------------------------------*/
/* ---------------------------------------------------------
color
----------------------------------------------------------*/
/* ---------------------------------------------------------
anime
----------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;700&family=Noto+Sans+JP:wght@400;500;700&display=swap");
.animeBefore,
.animeBefore2 {
  visibility: hidden;
}

.fadeAnime {
  visibility: visible;
  animation-name: fadeAnime;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
}

.fadeAnime2 {
  visibility: visible;
  animation-name: fadeAnime;
  animation-duration: 400ms;
}

.fadeAnimeOpc {
  visibility: visible !important;
  animation-name: fadeAnime2;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
}

.fadeLeftAnime {
  visibility: visible !important;
  animation-name: fadeLeftAnime;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
}

.fadeRightAnime {
  visibility: visible !important;
  animation-name: fadeRightAnime;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
}

@keyframes fadeAnime {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes fadeAnime2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeLeftAnime {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes fadeRightAnime {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
/* ---------------------------------------------------------
parts
----------------------------------------------------------*/
.sec-about__link, .sec-safe__link, .sec-news__btn__link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 348px;
  height: 60px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.02em;
  color: #5AA844;
  background: #fff;
  padding-right: 35px;
  border-radius: 30px;
  border: 2px solid #5AA844;
  transition: color 0.3s, background 0.3s;
}
@media screen and (max-width: 767px) {
  .sec-about__link, .sec-safe__link, .sec-news__btn__link {
    width: 36.7149758454vw;
    height: 8.2125603865vw;
    font-size: 2.8985507246vw;
    padding-right: 5.3140096618vw;
    border-radius: 4.1062801932vw;
  }
}
.sec-about__link::before, .sec-safe__link::before, .sec-news__btn__link::before, .sec-about__link::after, .sec-safe__link::after, .sec-news__btn__link::after {
  content: "";
  width: 12px;
  height: 10px;
  background: url("../img/common/icon_arrow_right.svg") no-repeat;
  background-size: 12px 10px;
  position: absolute;
  top: 50%;
  right: 96px;
  width: 17px;
  height: 13px;
  background-size: 17px 13px;
  margin-top: -6px;
  transition: opacity 0.3s;
}
@media screen and (max-width: 767px) {
  .sec-about__link::before, .sec-safe__link::before, .sec-news__btn__link::before, .sec-about__link::after, .sec-safe__link::after, .sec-news__btn__link::after {
    width: 3.3816425121vw;
    height: 2.6570048309vw;
    right: 4.5893719807vw;
    background-size: contain;
  }
}
.sec-about__link::after, .sec-safe__link::after, .sec-news__btn__link::after {
  content: "";
  width: 12px;
  height: 10px;
  background: url("../img/common/icon_arrow_right_white.svg") no-repeat;
  background-size: 12px 10px;
  width: 17px;
  height: 13px;
  background-size: 17px 13px;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
}
@media screen and (max-width: 767px) {
  .sec-about__link::after, .sec-safe__link::after, .sec-news__btn__link::after {
    width: 3.3816425121vw;
    height: 2.6570048309vw;
    background-size: contain;
  }
}
.sec-about__link:hover, .sec-safe__link:hover, .sec-news__btn__link:hover {
  color: #fff;
  background: #5AA844;
}
.sec-about__link:hover::before, .sec-safe__link:hover::before, .sec-news__btn__link:hover::before {
  opacity: 0;
}
.sec-about__link:hover::after, .sec-safe__link:hover::after, .sec-news__btn__link:hover::after {
  opacity: 1;
  transform: translateX(5px);
}

/* ---------------------------------------------------------
fonts
----------------------------------------------------------*/
/* font-display: swap; */
/* CSS Document */
body, .header {
  min-width: 1286px;
}
@media screen and (max-width: 767px) {
  body, .header {
    min-width: 100%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 100;
}

.loading__bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background-color: #5AA844;
  transform: scaleX(0);
  transform-origin: left top;
  transition: transform 0.6s;
}
.loading__bar.is-show {
  transform: scaleX(1);
}
.loading__txt {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 499px;
  transform: translate(-50%, -50%);
  animation-name: opcAnime;
  animation-duration: 800ms;
  animation-fill-mode: forwards;
}
@media screen and (max-width: 767px) {
  .loading__txt {
    width: 76.0869565217vw;
  }
}

@keyframes opcAnime {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* ---------------------------------------------------------
.kv
----------------------------------------------------------*/
.kv {
  position: relative;
  width: 100%;
}
.kv__block__sec01 {
  width: 1287px;
  height: 553px;
  background: url(../img/top/main_01.jpg) no-repeat;
  background-size: 100% auto;
  padding: 70px 0 76px;
  margin: 0 auto 2px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec01 {
    width: auto;
    height: auto;
    background: url(../img/top/main_01_sp.jpg) no-repeat;
    background-size: 100% auto;
    padding: 14.4927536232% 0 10.6280193237%;
    margin: 0 auto 0.4830917874%;
  }
}
.kv__block__sec01__lead {
  margin-bottom: 60px;
  visibility: hidden;
}
@media screen and (max-width: 767px) {
  .kv__block__sec01__lead {
    margin-bottom: 31.4009661836%;
  }
}
.kv__block__sec01__lead__txt01 {
  width: 259px;
  margin-bottom: 6px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec01__lead__txt01 {
    width: 52.6570048309%;
    margin-bottom: 1.4492753623%;
  }
}
.kv__block__sec01__lead__txt02 {
  width: 456px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec01__lead__txt02 {
    width: 91.5458937198%;
  }
}
.kv__block__sec01__bg--green {
  position: relative;
  position: relative;
  width: 1008px;
  margin: 0 auto;
  visibility: hidden;
}
.kv__block__sec01__bg--green img {
  position: relative;
}
.kv__block__sec01__bg--green::before {
  content: "";
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: 100%;
  background: #454545;
  opacity: 0.2;
  filter: blur(15px);
}
@media screen and (max-width: 767px) {
  .kv__block__sec01__bg--green::before {
    top: 0;
    left: 2.4154589372vw;
    margin-top: 3.6231884058vw;
    filter: blur(2.4154589372vw);
  }
}
@media screen and (max-width: 767px) {
  .kv__block__sec01__bg--green {
    position: relative;
    width: 80.6763285024%;
  }
  .kv__block__sec01__bg--green img {
    position: relative;
  }
  .kv__block__sec01__bg--green::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #454545;
    opacity: 0.15;
    filter: blur(15px);
  }
}
@media screen and (max-width: 767px) and (max-width: 767px) {
  .kv__block__sec01__bg--green::before {
    top: 0;
    left: 2.4154589372vw;
    margin-top: 3.6231884058vw;
    filter: blur(2.4154589372vw);
  }
}
.kv__block__sec01__bg--green__link {
  position: relative;
  display: block;
  background: #5AA844;
  padding: 22px 0 40px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec01__bg--green__link {
    padding: 2.6946107784% 0 6.2874251497%;
  }
}
.kv__block__sec01__bg--green__link:hover .kv__block__sec01__bg--green__btn {
  background: #0C0D0D;
  color: #fff;
}
.kv__block__sec01__bg--green__link:hover .kv__block__sec01__bg--green__btn::before {
  opacity: 0;
}
.kv__block__sec01__bg--green__link:hover .kv__block__sec01__bg--green__btn::after {
  opacity: 1;
  transform: translateX(5px);
}
.kv__block__sec01__bg--green__fig {
  position: absolute;
  top: -17px;
  left: 44px;
  width: 52px;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .kv__block__sec01__bg--green__fig {
    top: 0;
    left: 0;
    width: 8.9820359281%;
    margin-top: -3.8922155689%;
    margin-left: 10.4790419162%;
  }
}
.kv__block__sec01__bg--green__title {
  width: 624px;
  margin-bottom: 10px;
  margin-bottom: 3px;
  margin-left: 50px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec01__bg--green__title {
    width: 55.0898203593%;
    margin: 0 auto 0.8982035928%;
  }
}
.kv__block__sec01__bg--green__subtitle {
  font-size: 22px;
  color: #fff;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.05em;
  margin-bottom: 8px;
  margin-left: 51px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec01__bg--green__subtitle {
    font-size: 3.1400966184vw;
    line-height: 1.2;
    text-align: center;
    letter-spacing: 0;
    margin-bottom: 2.0958083832%;
    margin-left: 0;
  }
}
.kv__block__sec01__bg--green__lead {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  line-height: 1.77;
  margin-left: 51px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec01__bg--green__lead {
    font-size: 2.4154589372vw;
    text-align: center;
    line-height: 1.6;
    transform: scale(0.9);
    transform-origin: center top;
    text-align: center;
    margin: 0 0 1.497005988% 0;
  }
}
.kv__block__sec01__bg--green__wrapper {
  position: absolute;
  top: 20px;
  right: 40px;
  display: flex;
  justify-content: flex-end;
  width: 456px;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .kv__block__sec01__bg--green__wrapper {
    position: relative;
    top: auto;
    right: auto;
    justify-content: center;
    width: 83.2335329341%;
    margin: 0 auto;
  }
}
.kv__block__sec01__bg--green__txt--orange {
  width: 142px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec01__bg--green__txt--orange {
    width: 31.2949640288%;
  }
}
.kv__block__sec01__bg--green .kv__block__sec01__bg--green__txt--orange:nth-child(1) {
  margin-right: 20px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec01__bg--green .kv__block__sec01__bg--green__txt--orange:nth-child(1) {
    margin-right: 5.3956834532%;
  }
}
.kv__block__sec01__bg--green__num {
  position: absolute;
  bottom: 0;
  right: 26px;
  width: 148px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec01__bg--green__num {
    top: 0;
    right: 0;
    bottom: auto;
    width: 20.0598802395%;
    margin-top: 1.7964071856%;
    margin-right: 1.1976047904%;
  }
}
.kv__block__sec01__bg--green__num__img {
  margin-bottom: -2px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec01__bg--green__num__img {
    margin-bottom: 0;
  }
}
.kv__block__sec01__bg--green__btn {
  position: absolute;
  top: 203px;
  left: 414px;
  left: 323px;
  width: 348px;
  height: 60px;
  color: #0C0D0D;
  font-size: 16px;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 0.02em;
  background: #fff;
  border: 2px solid #0C0D0D;
  border-radius: 30px;
  padding-top: 21px;
  padding-left: 97px;
  margin: 0 auto;
  box-sizing: border-box;
  transition: color 0.3s, background 0.3s;
}
@media screen and (max-width: 767px) {
  .kv__block__sec01__bg--green__btn {
    top: 0;
    left: 0;
    width: 36.7149758454vw;
    height: 8.2125603865vw;
    font-size: 2.8985507246vw;
    letter-spacing: 0.02em;
    border-radius: 7.2463768116vw;
    padding-top: 2.1739130435vw;
    padding-left: 4.8309178744vw;
    margin: 52.1739130435vw 0 0 21.9806763285vw;
    margin: 50.9661835749vw 0 0 21.9806763285vw;
  }
}
.kv__block__sec01__bg--green__btn::before, .kv__block__sec01__bg--green__btn::after {
  content: "";
  position: absolute;
  top: 22px;
  right: 96px;
  width: 17px;
  height: 14px;
  background: url("../img/common/icon_arrow_right_black.svg") no-repeat;
  background-size: 17px 14px;
  transition: opacity 0.3s;
}
@media screen and (max-width: 767px) {
  .kv__block__sec01__bg--green__btn::before, .kv__block__sec01__bg--green__btn::after {
    width: 3.3816425121vw;
    height: 2.6570048309vw;
    top: 2.4154589372vw;
    right: 4.5893719807vw;
    background-size: contain;
  }
}
.kv__block__sec01__bg--green__btn::after {
  background: url(../img/common/icon_arrow_right_white.svg) no-repeat;
  background-size: 17px 14px;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
}
@media screen and (max-width: 767px) {
  .kv__block__sec01__bg--green__btn::after {
    background-size: contain;
  }
}
.kv__block__sec02 {
  width: 1287px;
  height: 340px;
  background: url(../img/top/main_02.jpg) no-repeat;
  background-size: 100% auto;
  padding: 68px 0 91px;
  margin: 0 auto 2px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec02 {
    width: auto;
    height: auto;
    background: url(../img/top/main_02_sp.jpg) no-repeat;
    background-size: 100% auto;
    padding: 27.0531400966% 0 6.7632850242%;
    margin: 0 auto 0.4830917874%;
  }
}
.kv__block__sec02__bg--green {
  position: relative;
  position: relative;
  width: 474px;
  margin-left: 673px;
  visibility: hidden;
}
.kv__block__sec02__bg--green img {
  position: relative;
}
.kv__block__sec02__bg--green::before {
  content: "";
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: 100%;
  background: #454545;
  opacity: 0.2;
  filter: blur(15px);
}
@media screen and (max-width: 767px) {
  .kv__block__sec02__bg--green::before {
    top: 0;
    left: 2.4154589372vw;
    margin-top: 3.6231884058vw;
    filter: blur(2.4154589372vw);
  }
}
@media screen and (max-width: 767px) {
  .kv__block__sec02__bg--green {
    position: relative;
    width: 80.6763285024%;
    margin: 0 auto;
  }
  .kv__block__sec02__bg--green img {
    position: relative;
  }
  .kv__block__sec02__bg--green::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #454545;
    opacity: 0.15;
    filter: blur(15px);
  }
}
@media screen and (max-width: 767px) and (max-width: 767px) {
  .kv__block__sec02__bg--green::before {
    top: 0;
    left: 2.4154589372vw;
    margin-top: 3.6231884058vw;
    filter: blur(2.4154589372vw);
  }
}
.kv__block__sec02__bg--green__link {
  position: relative;
  display: block;
  background: #5AA844;
  padding: 19px 0 42px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec02__bg--green__link {
    padding: 2.6946107784% 0 5.9880239521%;
  }
}
.kv__block__sec02__bg--green__link:hover .kv__block__sec02__bg--green__btn {
  background: #0C0D0D;
  color: #fff;
}
.kv__block__sec02__bg--green__link:hover .kv__block__sec02__bg--green__btn::before {
  opacity: 0;
}
.kv__block__sec02__bg--green__link:hover .kv__block__sec02__bg--green__btn::after {
  opacity: 1;
  transform: translateX(5px);
}
.kv__block__sec02__bg--green__title {
  width: 414px;
  margin-bottom: 8px;
  margin-left: 29px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec02__bg--green__title {
    width: 54.4910179641%;
    margin: 0 auto 1.497005988%;
  }
}
.kv__block__sec02__bg--green__subtitle {
  font-size: 14px;
  color: #fff;
  margin-bottom: 8px;
  margin-left: 31px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec02__bg--green__subtitle {
    font-size: 3.1400966184vw;
    line-height: 1.23;
    text-align: center;
    margin-left: 0;
    margin-bottom: 1.497005988%;
  }
}
.kv__block__sec02__bg--green__lead {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  line-height: 1.75;
  margin-left: 31px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec02__bg--green__lead {
    font-size: 2.4154589372vw;
    text-align: center;
    line-height: 1.81;
    transform: scale(0.9);
    transform-origin: center top;
    text-align: center;
    margin: 0;
  }
}
.kv__block__sec02__bg--green__num {
  position: absolute;
  bottom: 11px;
  right: 22px;
  width: 109px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec02__bg--green__num {
    top: 0;
    right: 0;
    bottom: auto;
    width: 24.251497006%;
    margin-top: 1.497005988%;
    margin-right: 1.7964071856%;
  }
}
.kv__block__sec02__bg--green__btn {
  position: absolute;
  top: 161px;
  left: 63px;
  width: 348px;
  height: 60px;
  color: #0C0D0D;
  font-size: 16px;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 0.02em;
  background: #fff;
  border: 2px solid #0C0D0D;
  border-radius: 30px;
  padding-top: 21px;
  padding-left: 97px;
  margin: 0 auto;
  box-sizing: border-box;
  transition: color 0.3s, background 0.3s;
}
@media screen and (max-width: 767px) {
  .kv__block__sec02__bg--green__btn {
    top: 0;
    left: 0;
    width: 36.7149758454vw;
    height: 8.2125603865vw;
    font-size: 2.8985507246vw;
    letter-spacing: 0.02em;
    border-radius: 7.2463768116vw;
    padding-top: 2.1739130435vw;
    padding-left: 4.8309178744vw;
    margin: 32.1256038647vw 0 0 21.9806763285vw;
    margin: 30.9178743961vw 0 0 21.9806763285vw;
  }
}
.kv__block__sec02__bg--green__btn::before, .kv__block__sec02__bg--green__btn::after {
  content: "";
  position: absolute;
  top: 22px;
  right: 96px;
  width: 17px;
  height: 14px;
  background: url("../img/common/icon_arrow_right_black.svg") no-repeat;
  background-size: 17px 14px;
  transition: opacity 0.3s;
}
@media screen and (max-width: 767px) {
  .kv__block__sec02__bg--green__btn::before, .kv__block__sec02__bg--green__btn::after {
    width: 3.3816425121vw;
    height: 2.6570048309vw;
    top: 2.4154589372vw;
    right: 4.5893719807vw;
    background-size: contain;
  }
}
.kv__block__sec02__bg--green__btn::after {
  background: url(../img/common/icon_arrow_right_white.svg) no-repeat;
  background-size: 17px 14px;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
}
@media screen and (max-width: 767px) {
  .kv__block__sec02__bg--green__btn::after {
    background-size: contain;
  }
}
.kv__block__sec03 {
  width: 1287px;
  height: 340px;
  background: url(../img/top/main_03.jpg) no-repeat;
  background-size: 100% auto;
  padding: 68px 0 91px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .kv__block__sec03 {
    width: auto;
    height: auto;
    background: url(../img/top/main_03_sp.jpg) no-repeat;
    background-size: 100% auto;
    padding: 29.4685990338% 0 11.5942028986%;
    padding: 27.0531400966% 0 6.7632850242%;
  }
}
.kv__block__sec03__bg--green {
  position: relative;
  position: relative;
  width: 474px;
  margin-left: 139px;
  visibility: hidden;
}
.kv__block__sec03__bg--green img {
  position: relative;
}
.kv__block__sec03__bg--green::before {
  content: "";
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: 100%;
  background: #454545;
  opacity: 0.2;
  filter: blur(15px);
}
@media screen and (max-width: 767px) {
  .kv__block__sec03__bg--green::before {
    top: 0;
    left: 2.4154589372vw;
    margin-top: 3.6231884058vw;
    filter: blur(2.4154589372vw);
  }
}
@media screen and (max-width: 767px) {
  .kv__block__sec03__bg--green {
    position: relative;
    width: 80.6763285024%;
    margin: 0 auto;
  }
  .kv__block__sec03__bg--green img {
    position: relative;
  }
  .kv__block__sec03__bg--green::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #454545;
    opacity: 0.15;
    filter: blur(15px);
  }
}
@media screen and (max-width: 767px) and (max-width: 767px) {
  .kv__block__sec03__bg--green::before {
    top: 0;
    left: 2.4154589372vw;
    margin-top: 3.6231884058vw;
    filter: blur(2.4154589372vw);
  }
}
.kv__block__sec03__bg--green__link {
  position: relative;
  display: block;
  background: #5AA844;
  padding: 19px 0 42px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec03__bg--green__link {
    padding: 2.994011976% 0 5.9880239521%;
  }
}
.kv__block__sec03__bg--green__link:hover .kv__block__sec03__bg--green__btn {
  background: #0C0D0D;
  color: #fff;
}
.kv__block__sec03__bg--green__link:hover .kv__block__sec03__bg--green__btn::before {
  opacity: 0;
}
.kv__block__sec03__bg--green__link:hover .kv__block__sec03__bg--green__btn::after {
  opacity: 1;
  transform: translateX(5px);
}
.kv__block__sec03__bg--green__title {
  width: 414px;
  margin-bottom: 11px;
  margin-left: 29px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec03__bg--green__title {
    width: 51.497005988%;
    margin: 0 auto 2.3952095808%;
  }
}
.kv__block__sec03__bg--green__subtitle {
  font-size: 14px;
  color: #fff;
  margin-bottom: 8px;
  margin-left: 31px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec03__bg--green__subtitle {
    font-size: 3.1400966184vw;
    line-height: 1.23;
    text-align: center;
    margin-left: 0;
    margin-bottom: 1.497005988%;
  }
}
.kv__block__sec03__bg--green__lead {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  line-height: 1.68;
  margin-left: 31px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec03__bg--green__lead {
    font-size: 2.4154589372vw;
    text-align: center;
    line-height: 1.81;
    transform: scale(0.9);
    transform-origin: center top;
    text-align: center;
    margin: 0;
  }
}
.kv__block__sec03__bg--green__num {
  position: absolute;
  bottom: 0;
  right: 11px;
  width: 109px;
}
@media screen and (max-width: 767px) {
  .kv__block__sec03__bg--green__num {
    top: 0;
    right: 0;
    bottom: auto;
    width: 24.251497006%;
    margin-top: 2.3952095808%;
    margin-right: 0.8982035928%;
  }
}
.kv__block__sec03__bg--green__btn {
  position: absolute;
  top: 161px;
  left: 63px;
  width: 348px;
  height: 60px;
  color: #0C0D0D;
  font-size: 16px;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 0.02em;
  background: #fff;
  border: 2px solid #0C0D0D;
  border-radius: 30px;
  padding-top: 21px;
  padding-left: 97px;
  margin: 0 auto;
  box-sizing: border-box;
  transition: color 0.3s, background 0.3s;
}
@media screen and (max-width: 767px) {
  .kv__block__sec03__bg--green__btn {
    top: 0;
    left: 0;
    width: 36.7149758454vw;
    height: 8.2125603865vw;
    font-size: 2.8985507246vw;
    letter-spacing: 0.02em;
    border-radius: 7.2463768116vw;
    padding-top: 2.1739130435vw;
    padding-left: 4.8309178744vw;
    margin: 32.1256038647vw 0 0 21.9806763285vw;
    margin: 30.9178743961vw 0 0 21.9806763285vw;
  }
}
.kv__block__sec03__bg--green__btn::before, .kv__block__sec03__bg--green__btn::after {
  content: "";
  position: absolute;
  top: 22px;
  right: 96px;
  width: 17px;
  height: 14px;
  background: url("../img/common/icon_arrow_right_black.svg") no-repeat;
  background-size: 17px 14px;
  transition: opacity 0.3s;
}
@media screen and (max-width: 767px) {
  .kv__block__sec03__bg--green__btn::before, .kv__block__sec03__bg--green__btn::after {
    width: 3.3816425121vw;
    height: 2.6570048309vw;
    top: 2.4154589372vw;
    right: 4.5893719807vw;
    background-size: contain;
  }
}
.kv__block__sec03__bg--green__btn::after {
  background: url(../img/common/icon_arrow_right_white.svg) no-repeat;
  background-size: 17px 14px;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
}
@media screen and (max-width: 767px) {
  .kv__block__sec03__bg--green__btn::after {
    background-size: contain;
  }
}

/* ---------------------------------------------------------
.main
----------------------------------------------------------*/
.main {
  display: block;
}

/* ---------------------------------------------------------
.sec-about
----------------------------------------------------------*/
.sec-about {
  position: relative;
  padding: 121px 0 100px;
}
@media screen and (max-width: 767px) {
  .sec-about {
    padding: 15.2173913043% 0 14.4927536232%;
    overflow: hidden;
  }
}
.sec-about::before {
  content: "";
  position: absolute;
  right: 50%;
  bottom: 0;
  width: 50vw;
  height: 530px;
  background: #F5F9FC;
  margin-right: 272px;
}
@media screen and (max-width: 767px) {
  .sec-about::before {
    right: 0;
    width: 100%;
    height: 38.6473429952vw;
    margin: 0;
  }
}
.sec-about__wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 1088px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .sec-about__wrapper {
    display: block;
    width: 100%;
  }
}
.sec-about__right-col {
  width: 484px;
  padding-top: 88px;
}
@media screen and (max-width: 767px) {
  .sec-about__right-col {
    width: 100%;
    padding: 0 0 0 4.8309178744%;
  }
}
.sec-about__title--en {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #5AA844;
}
@media screen and (max-width: 767px) {
  .sec-about__title--en {
    font-size: 3.3816425121vw;
    line-height: 1;
  }
}
.sec-about__title--en::after {
  content: "…";
  display: block;
  font-size: 18px;
  font-weight: normal;
  margin-top: 1px;
}
@media screen and (max-width: 767px) {
  .sec-about__title--en::after {
    font-size: 3.8647342995vw;
    line-height: 1;
    margin: -0.4830917874vw;
  }
}
.sec-about__title {
  font-size: 39px;
  font-weight: 500;
  letter-spacing: 0.03em;
  margin-top: 1px;
}
@media screen and (max-width: 767px) {
  .sec-about__title {
    font-size: 5.7971014493vw;
    line-height: 1;
    letter-spacing: normal;
    margin-top: 1.2077294686vw;
  }
}
.sec-about__title--small {
  font-size: 30px;
  margin-left: 8px;
}
@media screen and (max-width: 767px) {
  .sec-about__title--small {
    font-size: 5.7971014493vw;
    line-height: 1;
    margin-left: 1.2077294686vw;
  }
}
.sec-about__txt {
  font-size: 16px;
  line-height: 28px;
  margin-top: 26px;
}
@media screen and (max-width: 767px) {
  .sec-about__txt {
    font-size: 3.3816425121vw;
    line-height: 1.57;
    padding-right: 7.2463768116vw;
    margin-top: 5.3140096618vw;
  }
}
.sec-about__link {
  margin-top: 28px;
}
@media screen and (max-width: 767px) {
  .sec-about__link {
    margin: 5.3140096618vw 4.8309178744vw 0 auto;
  }
}
.sec-about__pic {
  width: 544px;
  position: relative;
}
.sec-about__pic img {
  position: relative;
}
.sec-about__pic::before {
  content: "";
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: 100%;
  background: #454545;
  opacity: 0.2;
  filter: blur(15px);
}
@media screen and (max-width: 767px) {
  .sec-about__pic::before {
    top: 0;
    left: 2.4154589372vw;
    margin-top: 3.6231884058vw;
    filter: blur(2.4154589372vw);
  }
}
@media screen and (max-width: 767px) {
  .sec-about__pic {
    position: relative;
    width: 95.1690821256%;
    margin-left: auto;
    margin-top: 7.2463768116%;
  }
  .sec-about__pic img {
    position: relative;
  }
  .sec-about__pic::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #454545;
    opacity: 0.15;
    filter: blur(15px);
  }
}
@media screen and (max-width: 767px) and (max-width: 767px) {
  .sec-about__pic::before {
    top: 0;
    left: 2.4154589372vw;
    margin-top: 3.6231884058vw;
    filter: blur(2.4154589372vw);
  }
}

/* ---------------------------------------------------------
.sec-safe
----------------------------------------------------------*/
.sec-safe {
  position: relative;
  padding: 99px 0 100px;
}
@media screen and (max-width: 767px) {
  .sec-safe {
    padding: 15.2173913043% 0 14.4927536232%;
    overflow: hidden;
  }
}
.sec-safe::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 235px;
  background: #F5F9FC;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .sec-safe::before {
    height: 38.6473429952vw;
  }
}
.sec-safe__inner {
  width: 1088px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .sec-safe__inner {
    width: 100%;
    padding: 0 0 0 4.8309178744%;
  }
}
.sec-safe__title--en {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #5AA844;
}
@media screen and (max-width: 767px) {
  .sec-safe__title--en {
    font-size: 3.3816425121vw;
    line-height: 1;
  }
}
.sec-safe__title--en::after {
  content: "…";
  display: block;
  font-size: 18px;
  font-weight: normal;
  margin-top: 1px;
}
@media screen and (max-width: 767px) {
  .sec-safe__title--en::after {
    font-size: 3.8647342995vw;
    line-height: 1;
    margin: -0.4830917874vw;
  }
}
.sec-safe__title {
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0.03em;
  margin-top: 1px;
}
@media screen and (max-width: 767px) {
  .sec-safe__title {
    font-size: 5.7971014493vw;
    line-height: 1;
    letter-spacing: normal;
    margin-top: 1.2077294686vw;
  }
}
.sec-safe__title--small {
  font-size: 30px;
  margin-left: 8px;
}
@media screen and (max-width: 767px) {
  .sec-safe__title--small {
    font-size: 5.7971014493vw;
    line-height: 1;
    margin-left: 1.2077294686vw;
  }
}
.sec-safe__txt {
  font-size: 16px;
  line-height: 28px;
  margin-top: 28px;
}
@media screen and (max-width: 767px) {
  .sec-safe__txt {
    font-size: 3.3816425121vw;
    line-height: 1.57;
    padding-right: 7.2463768116vw;
    margin-top: 6.2801932367vw;
  }
}
.sec-safe__link {
  margin-top: 28px;
}
@media screen and (max-width: 767px) {
  .sec-safe__link {
    margin: 6.7632850242vw 4.8309178744vw 0 auto;
  }
}
.sec-safe__pic {
  position: relative;
  margin-top: 60px;
}
.sec-safe__pic img {
  position: relative;
}
.sec-safe__pic::before {
  content: "";
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: 100%;
  background: #454545;
  opacity: 0.15;
  filter: blur(15px);
}
@media screen and (max-width: 767px) {
  .sec-safe__pic::before {
    top: 0;
    left: 2.4154589372vw;
    margin-top: 3.6231884058vw;
    filter: blur(2.4154589372vw);
  }
}
@media screen and (max-width: 767px) {
  .sec-safe__pic {
    margin-top: 7.729468599%;
  }
}

/* ---------------------------------------------------------
.sec-news
----------------------------------------------------------*/
.sec-news {
  position: relative;
  background: #F5F9FC;
  padding: 100px 0 130px;
}
@media screen and (max-width: 767px) {
  .sec-news {
    padding: 14.4927536232% 0 21.7391304348%;
  }
}
.sec-news__inner {
  position: relative;
  width: 1088px;
  padding: 58px 0 90px;
  margin: 0 auto;
  position: relative;
}
.sec-news__inner img {
  position: relative;
}
.sec-news__inner::before {
  content: "";
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: 100%;
  background: #454545;
  opacity: 0.06;
  filter: blur(15px);
}
@media screen and (max-width: 767px) {
  .sec-news__inner::before {
    top: 0;
    left: 2.4154589372vw;
    margin-top: 3.6231884058vw;
    filter: blur(2.4154589372vw);
  }
}
@media screen and (max-width: 767px) {
  .sec-news__inner {
    width: 90.3381642512%;
    padding: 5.5555555556% 0 14.4927536232%;
  }
}
.sec-news__inner::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}
.sec-news__title--en {
  position: relative;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #5AA844;
  font-size: 14px;
  text-align: center;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .sec-news__title--en {
    font-size: 3.3816425121vw;
    line-height: 1;
  }
}
.sec-news__title--en::after {
  content: "…";
  display: block;
  font-size: 18px;
  font-weight: normal;
  margin-top: 1px;
}
@media screen and (max-width: 767px) {
  .sec-news__title--en::after {
    font-size: 3.8647342995vw;
    line-height: 1;
    margin: -0.4830917874vw;
  }
}
@media screen and (max-width: 767px) {
  .sec-news__title--en {
    font-size: 3.3816425121vw;
  }
}
.sec-news__title {
  position: relative;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.06em;
  text-align: center;
  line-height: 1;
  margin-top: 6px;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .sec-news__title {
    font-size: 5.3140096618vw;
  }
}
.sec-news__list {
  position: relative;
  width: 857px;
  margin: 56px auto 0;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .sec-news__list {
    width: 94.6524064171%;
    margin-top: 6.2801932367vw;
    border-top: 1px solid #b7b7b7;
  }
}
.sec-news__link {
  display: flex;
  background: #fff;
  transition: background 0.3s;
}
@media screen and (max-width: 767px) {
  .sec-news__link {
    display: block;
  }
}
.sec-news__link:hover {
  background: #F8F8F8;
}
.sec-news__left-col {
  width: 181px;
  min-height: 100px;
  padding: 20px 0 0 20px;
  border-bottom: 1px solid #b7b7b7;
}
@media screen and (max-width: 767px) {
  .sec-news__left-col {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: auto;
    padding: 4.8309178744vw 2.4154589372vw 0vw;
    border: none;
  }
}
.sec-news__date {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.02em;
  line-height: 1;
  color: #7C7C7C;
  padding-top: 4px;
}
@media screen and (max-width: 767px) {
  .sec-news__date {
    font-size: 3.3816425121vw;
    padding: 0;
  }
}
.sec-news__cat {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 20px;
  font-size: 12px;
  letter-spacing: 0.04em;
  color: #454545;
  background: #EEF8DC;
  border-radius: 10px;
  margin-top: 13px;
}
@media screen and (max-width: 767px) {
  .sec-news__cat {
    width: 28.9855072464vw;
    height: 4.8309178744vw;
    font-size: 2.8985507246vw;
    padding: 0;
    margin: 0 0 0 2.4154589372vw;
  }
}
.sec-news__right-col {
  display: flex;
  flex-direction: row-reverse;
  width: 676px;
  padding: 20px 20px 20px 0;
  border-bottom: 1px solid #b7b7b7;
}
@media screen and (max-width: 767px) {
  .sec-news__right-col {
    width: 100%;
    padding: 2.1739130435vw 2.4154589372vw 4.8309178744vw;
  }
}
.sec-news__txt {
  width: 100%;
  font-size: 16px;
  line-height: 28px;
}
@media screen and (max-width: 767px) {
  .sec-news__txt {
    font-size: 3.3816425121vw;
    line-height: 1.5714285714;
  }
}
.sec-news__pic {
  flex-grow: 0;
  flex-shrink: 0;
  width: 122px;
  padding-top: 2px;
  margin-left: 20px;
}
@media screen and (max-width: 767px) {
  .sec-news__pic {
    width: 26.8115942029vw;
    padding: 0;
    padding-top: 0.2415458937vw;
    margin-left: 3.1400966184vw;
  }
}
.sec-news__btn__link {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .sec-news__btn__link {
    bottom: -3.8647342995vw;
  }
}
